import React, { useState, useEffect, useRef } from 'react'

import Layout from '../components/Layout'
import { css } from '@emotion/core'

export default () => {
    
    const [height, setHeight] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    },[ref.current])

    return (
        <Layout>
            <div className="about">
                <div className="about__pre">
                    <hr/>
                </div>
                <div css={css`
                    @media only screen and (max-width: 26.5625em) {
                        flex: 1 !important;
                    }
                `} className="about__container">
                    <div className="no-small" style={{ width: '10px', height: height-128, backgroundColor: 'var(--color-primary)', position: 'relative',marginTop: -51 }} />
                    <div ref={ref} className="content">
                        <div className="about__title" style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
                            <div style={{ width: '4rem', height: '2px', backgroundColor: 'var(--color-primary)', marginLeft: '-2.5rem', marginRight: '1rem' }} className="line-hor" />
                            <h1>¿Qué es COLUMNA?</h1>
                        </div>
                        <p>Somos una plataforma mexicana enfocada en la promoción de la cultura y el arte a través de proyectos innovadores que buscan la creación de espacios y eventos que sean inclusivos  para todos.</p>
                        <div className="about__title" style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
                            <div style={{ width: '4rem', height: '2px', backgroundColor: 'var(--color-primary)', marginLeft: '-2.5rem', marginRight: '1rem' }} className="line-hor" />
                            <h3>Objetivos</h3>
                        </div>
                        <ol >
                            <li>. Acercar el arte y la cultura a los jóvenes</li>
                            <li>. Generar conciencia acerca de la importancia del arte y la cultura en un país</li>
                            <li>. Crear proyectos de calidad que impulsen a artistas emergentes</li>
                            <li>. Posicionar a nuestros proyectos dentro de los eventos culturales más importantes de la CDMX</li>
                            <li>. Apoyar el intercambio cultural entre artistas mexicanos e internacionales</li>
                        </ol>
                        <div className="about__title" style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
                            <div style={{ width: '4rem', height: '2px', backgroundColor: 'var(--color-primary)', marginLeft: '-2.5rem', marginRight: '1rem' }} className="line-hor" />
                            <h3>Nuestro Equipo</h3>
                        </div>
                        <ol>
                            <li>. Samantha Calderón / Co-fundadora y Directora</li>
                            <li>. Alejandro Pérez De Alba / Co-fundor y Curador</li>
                            <li>. Joe Ghines / Co-fundador</li>
                        </ol>
                    </div>
                </div>
            </div>
        </Layout>
    )
}